var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function cleanTagIds(raw) {
    return raw.map(function (id) { return id.trim(); }) // Trim each ID entry to remove whitespace
        .filter(function (id) { return /^\d+$/.test(id); }) // Use a regular expression to ensure the ID is entirely numeric.
        .map(function (id) { return Number(id); }); // Convert the remaining, valid ID entries into numbers
}
// Function to get the value of the "merchi_source" query parameter.
var getMerchiSourceValue = function () {
    // Using URLSearchParams to parse query parameters
    if (typeof window !== 'undefined') {
        var searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('merchi_source');
    }
    return null;
};
// Function to retrieve and extend the merchi_source value in localStorage.
export var extendMerchiSourceInLocalStorage = function () {
    var merchiSourceFromUrl = getMerchiSourceValue(); // Retrieve new value from URL.
    if (merchiSourceFromUrl !== null) {
        try {
            if (typeof localStorage !== 'undefined' && localStorage !== null) {
                // Retrieve the current merchi_source value from localStorage and split it into an array.
                var currentMerchiSource = localStorage.getItem('merchi_source');
                var currentIds = currentMerchiSource ? currentMerchiSource.split(',') : [];
                // Split the new merchiSourceFromUrl value into an array.
                var newIds = merchiSourceFromUrl.split(',');
                // Create a set combining the two arrays to remove duplicates and convert back to an array.
                var combinedIdsArray = Array.from(new Set(__spreadArray(__spreadArray([], __read(currentIds), false), __read(newIds), false)));
                var cleanedIds = cleanTagIds(combinedIdsArray);
                // Convert the array back into a comma-separated string.
                var combinedIdsString = cleanedIds.join(',');
                // Store the new unique combined value in localStorage.
                localStorage.setItem('merchi_source', combinedIdsString);
            }
        }
        catch (error) {
            console.error('Error extending "merchi_source" in localStorage:', error);
            // Handle the error as required for your application.
            // e.g., alert user, report to an error logging service, etc.
        }
    }
    else {
        // If merchiSourceFromUrl is null, we might want to log this situation or handle it a specific way.
        console.warn('No "merchi_source" value found in the URL query parameters.');
    }
};
export function clearMerchiSource() {
    localStorage.removeItem('merchi_source');
}
